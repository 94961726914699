import React from "react"
import * as styles from "./subscriptionList.module.css"
import changeSubsCheckBtn from "images/changeSubsCheckBtn.png"

const SubscriptionList = props => {
  return (
    <div className={styles.main} name="big">
      <img src={changeSubsCheckBtn} className={styles.mainImg} id={props.name} alt={"changeSubsCheckBtn"} />
      <div
        type="checkbox"
        id={props.name}
        name="checkbox"
        defaultChecked={""}
        className={styles.button}
      />
      <label
        htmlFor={props.name}
        className={styles.con}
        onClick={props.onClick_}
        onMouseOver={props.onMouseOver_}
        onMouseOut={props.onMouseOut_}
        id={props.name}
        style={{ backgroundColor: `${props.checked ? "#DFDFDF" : null}` }}
      >
        <div id={props.name}>
          <p id={props.name}>{props.title}</p>
          <p id={props.name}>매 월 {props.price.toLocaleString()}원</p>
        </div>
      </label>
    </div>
  )
}

export default SubscriptionList
