import React, { Fragment, useContext, useState } from "react"
import Layout from "components/common/layout.js"
import SettingLayout from "components/member/Setting/settingLayout"
import SubscriptionList from "components/member/Setting/subscriptionList"
import modalContext from "context/modalContext"
import TwoBtnModal from "components/tools/modalComponents/twoBtnModal"
import SubsLogo from "images/subsLogo.png"
import { navigate } from "gatsby"

const ChangeSubscriptionPage = props => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const subsList = [
    ["피치서가 온라인 정기구독", 30000, 0],
    ["피치서가 온라인과 월간 피치서가 정기구독", 42000, 1],
  ]

  const [currentMenu, setcurrentMenu] = useState(null)

  const onClick_ = ({ target }) => {
    console.log(target.parentElement)
    var bigLabels = document.getElementsByTagName("label")

    if (currentMenu) {
      currentMenu.style.display = "none"

      for (var i = 0; i < bigLabels.length; i++) {
        if (bigLabels[i].id == currentMenu.id) {
          bigLabels[i].style.border = "1px solid #dfdfdf"
        }
      }
    }

    var labels = document.getElementsByTagName("img")
    for (var i = 0; i < labels.length; i++) {
      if (labels[i].id == target.parentElement.id) {
        setcurrentMenu(labels[i])
        labels[i].style.display = "block"
      }
    }
    for (var i = 0; i < bigLabels.length; i++) {
      if (bigLabels[i].id == target.parentElement.id) {
        bigLabels[i].style.border = "1px solid #e95c63"
      }
    }
  }

  const ChangeSubs_ = () => {
    if (currentMenu) {
      setmodalOpen(true)
      setModalMain(
        <TwoBtnModal
          close={() => {
            setmodalOpen(false)
          }}
          size={{
            w: 642,
            h: 600,
          }}
          src={SubsLogo}
          title="구독 플랜을 변경하시겠습니까?"
          confirm="네"
          cancel="아니요"
          className={{
            btn_1: ".",
            btn_2: ".",
          }}
        >
          <div>
            <div>
              <p style={{ marginLeft: "40px", marginBottom: "5px" }}>
                현재 구독 플랜
              </p>
              <SubscriptionList
                name={0}
                title={subsList[0][0]}
                price={subsList[0][1]}
                checked={subsList[0][2]}
              />
            </div>

            <div>
              <p style={{ marginLeft: "40px", marginBottom: "5px" }}>
                새로운 구독 플랜
              </p>
              <SubscriptionList
                name={0}
                title={subsList[0][0]}
                price={subsList[0][1]}
                checked={subsList[0][2]}
              />
            </div>

            <p style={{ margin: "0 0 10px 40px", color: "#7C7C7C" }}>
              2021년 6월 15일부터 새로운 구독 플랜으로 매 월 00,000원이
              결제됩니다.
            </p>
          </div>
        </TwoBtnModal>
      )
    }
  }

  return (
    <Layout settingHeader>
      <SettingLayout title="구독 상품 변경" />

      {subsList.map((v, idx) => (
        <Fragment key={idx}>
          <SubscriptionList
            name={idx}
            title={v[0]}
            price={v[1]}
            checked={v[2]}
            onClick_={onClick_}
          />
        </Fragment>
      ))}

      <div style={{ margin: "60px 0 " }}>
        <p style={{ width: "500px", textAlign: "left", marginLeft: "230px" }}>
          변경된 구독 상품은 다음 결제일 후 이용할 수 있습니다.
          <br />
          다음 결제일 전까지 구독상품은 변경이 가능하며, 결제 후 철회는
          불가합니다.
        </p>
      </div>

      <div
        style={{
          display: "flex",
          placeContent: "center",
          marginBottom: "200px",
        }}
      >
        <div
          className={"confirmBtn"}
          style={{ marginRight: "60.5px" }}
          onClick={ChangeSubs_}
        >
          다음
        </div>
        <div
          className={"cancelBtn"}
          onClick={() => navigate("/member/setting/SettingAccount")}
        >
          취소
        </div>
      </div>
    </Layout>
  )
}

export default ChangeSubscriptionPage
